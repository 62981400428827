<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-btn small fab dark color="info" @click="$router.go(-1)" class="mx-1">
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <InteractiveTable
              ref="eventRegistrationList"
              class="lg-table"
              :headers="[
                { text: 'ID', value: 'id' },
                { text: 'Vrsta', value: 'type' },
                { text: 'Status', value: 'status' },
                { text: 'Iznos', value: 'amount', align: 'end' },
                { text: 'Izvod', value: 'statement_num' },
                { text: 'Datum', value: 'date_created' },
                { text: 'Ime', value: 'contact_first_name' },
                { text: 'Prezime', value: 'contact_last_name' },
                { text: 'Poduzeće', value: 'company_name' },
                { text: 'Org. jedinica', value: 'company_unit' }
              ]"
              :apiLoad="
                'event/eventregistrations.php?event=' + $route.params.id
              "
              :statusColorFunc="statusColorFunc"
              :statusVisibleFunc="statusVisibleFunc"
              :actions="[
                {
                  id: '1',
                  name: 'Prijavnica',
                  icon: 'picture_as_pdf',
                  apiDownloadCall: 'event/document.php?type=rform',
                  color: 'orange'
                },
                {
                  id: '2',
                  name: 'Ponuda',
                  icon: 'picture_as_pdf',
                  apiDownloadCall: 'event/document.php?type=quote',
                  color: 'error'
                },
                {
                  id: '3',
                  name: 'Račun za predujam',
                  icon: 'picture_as_pdf',
                  apiDownloadCall: 'event/document.php?type=preinvoice',
                  color: 'secondary'
                },
                {
                  id: '4',
                  name: 'Račun',
                  icon: 'picture_as_pdf',
                  apiDownloadCall: 'event/document.php?type=invoice',
                  color: 'green'
                }
              ]"
              defaultFilter="contact_last_name"
              defaultSort="date_created"
              defaultSortDesc
              participantsFilter
            ></InteractiveTable>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "EventRegistrationList",
  data() {
    return {};
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.eventRegistrationListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.$refs.eventRegistrationList.reload();
      this.$store.dispatch("event_registration_list_refreshed");
    },
    statusColorFunc(status) {
      var col = "white";
      switch (status) {
        case "NEMA UPLATE":
          col = "error";
          break;
        case "NEMA PONUDE":
          col = "info";
          break;
        case "PLAĆENO":
          col = "primary";
          break;
        case "DJELOMIČNO":
          col = "yellow";
          break;
        case "PREPLATA":
          col = "accent";
          break;
        case "PREDRAČUN":
          col = "secondary";
          break;
        case "RAČUN":
          col = "green";
          break;
        case "NEPOZNATO":
          col = "grey";
          break;
      }
      return col;
    },
    statusVisibleFunc(status, id) {
      var visible = false;
      switch (status) {
        case "NEMA UPLATE":
          if (id == 1 || id == 2) visible = true;
          break;
        case "NEMA PONUDE":
          if (id == 1) visible = true;
          break;
        case "PLAĆENO":
          if (id == 1 || id == 2) visible = true;
          break;
        case "DJELOMIČNO":
          if (id == 1 || id == 2) visible = true;
          break;
        case "PREPLATA":
          if (id == 1 || id == 2) visible = true;
          break;
        case "PREDRAČUN":
          if (id == 1 || id == 2 || id == 3) visible = true;
          break;
        case "RAČUN":
          visible = true;
          break;
        case "NEPOZNATO":
          visible = false;
          break;
      }
      return visible;
    }
  }
};
</script>
