<template>
  <div>
    <v-container v-if="!model" fluid class="pa-0">
      <v-row>
        <span class="subtitle-2 mt-4 ml-4">{{ $route.params.name }}</span>
        <v-spacer></v-spacer>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                x-small
                fab
                dark
                class="mx-1"
                color="info"
                v-if="dateFilter"
                @click="dateVisible = !dateVisible"
              >
                <v-icon dark>date_range</v-icon>
              </v-btn>
            </template>
            <span>Stanje na dan {{ formatDate(date) }}</span>
          </v-tooltip>
          <v-slide-y-transition>
            <v-date-picker
              v-model="date"
              class="dateinput"
              v-if="dateVisible"
              @input="dateVisible = false"
              locale="hr-HR"
            ></v-date-picker>
          </v-slide-y-transition>
        </div>
        <v-checkbox
          v-if="negativeFilter"
          label="Prikaži isplate"
          v-model="negative"
          class="negative"
        ></v-checkbox>
        <v-checkbox
          v-if="participantsFilter"
          label="Prikaži sudionike"
          v-model="participants"
          class="negative"
        ></v-checkbox>
        <v-select
          v-if="yearFilter"
          :items="yearList"
          label="Godina"
          single-line
          prepend-icon="calendar"
          dense
          color="dark-grey"
          class="small-width caption"
          v-model="year"
        ></v-select>
        <v-select
          v-if="typeFilter"
          :label="typeFilterLabel"
          :items="typeFilter"
          v-model="selectedType"
          :item-value="typeFilterId"
          :item-text="typeFilterText"
          single-line
          dense
          color="dark-grey"
          class="medium-width caption"
        ></v-select>
        <v-select
          v-if="selectFilter"
          :label="selectFilterLabel"
          :items="selectFilter"
          v-model="selectedFilter"
          :item-value="selectFilterId"
          :item-text="selectFilterText"
          single-line
          dense
          color="dark-grey"
          class="medium-width caption"
        ></v-select>
        <v-select
          :items="headers"
          label="Filter"
          single-line
          prepend-icon="filter_list"
          dense
          color="dark-grey"
          class="small-width caption"
          v-model="filter"
        ></v-select>
        <v-text-field
          color="dark-grey"
          append-icon="search"
          class="ml-3 mr-2 small-width caption no-padding"
          v-model="search"
        ></v-text-field>
      </v-row>
    </v-container>
    <v-data-table
      ref="dataTable"
      :headers="headerCols"
      :items="rows"
      :options.sync="options"
      :server-items-length="totalRows"
      :loading="loading"
      :page.sync="cpage"
      fixed-header
      dense
      class="elevation-1"
      :show-select="multiSelect"
      v-model="selected"
      @click:row="rowClick"
      :items-per-page="itemsPerPage"
      :hide-default-footer="!!model"
      :footer-props="{
        itemsPerPageOptions: [50, 100, 200]
      }"
    >
      <template v-slot:[`item.actions`]="{ item }" :actions="this.actions">
        <div class="no-wrap">
          <v-tooltip v-for="act in actions" bottom :key="act.id">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="act.apiDeleteCall != null"
                x-small
                text
                icon
                :color="act.color"
                @click="callDeleteApi(act.apiDeleteCall, item.id)"
                v-on="on"
              >
                <v-icon>{{ act.icon }}</v-icon>
              </v-btn>
              <div v-else-if="
                  act.apiDownloadCall != null &&
                    (statusVisibleFunc == null ||
                      (statusVisibleFunc != null &&
                        statusVisibleFunc(item.status, act.id) == true))
                " style="display:inline">
              <v-btn
                x-small
                text
                icon
                :color="act.color"
                @click.stop="callDownloadApi(act.apiDownloadCall, item.id)"
                v-on="on"
              >
                <v-icon>{{ act.icon }}</v-icon>
              </v-btn>
              <v-btn 
                x-small
                text
                icon
                :color="act.color"
                @click.stop="callDownloadApiInline(act.apiDownloadCall, item.id)"
                v-on="on"
              >
                <v-icon>visibility</v-icon>
              </v-btn>
              </div>
              <v-btn
                v-else-if="act.eventCall != null"
                x-small
                text
                icon
                :color="act.color"
                @click.stop="$emit(act.eventCall, item)"
                v-on="on"
              >
                <v-icon>{{ act.icon }}</v-icon>
              </v-btn>
              <v-btn
                v-else-if="act.path != null"
                x-small
                text
                icon
                :color="
                  act.icon == 'payment' && act.color.length == 3
                    ? act.color[item.payment_type]
                    : act.color
                "
                @click.stop="
                  $router.push({
                    path: act.path
                      .replace('#id#', item.id)
                      .replace('#user#', item.user)
                      .replace('#name#', item.name)
                      .replace('#id2#', item.id2)
                  })
                "
                v-on="on"
              >
                <v-icon>{{ act.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ act.name }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="statusColorFunc(item.status)" x-small dark class="my-1">
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.estatus`]="{ item }">
        <v-chip :color="statusColorFunc(item.estatus)" x-small dark class="my-1">
          {{ item.estatus }}
        </v-chip>
      </template>
      <template v-slot:[`item.pstatus`]="{ item }">
        <v-chip :color="statusColorFunc(item.pstatus)" x-small dark class="my-1">
          {{ item.pstatus }}
        </v-chip>
      </template>
      <template v-slot:[`item.membership_private`]="{ item }">
        <v-chip :color="item.membership_private==1 ? 'blue' : 'green'" x-small dark class="my-1">
          {{ item.membership_private==1 ? 'PRIVATNO' : 'PODUZEĆE' }}
        </v-chip>
      </template>
      <template v-slot:[`item.amount_total`]="{ item }">
        <span>{{ item.amount_total ? formatCurrency(item.amount_total,item.currency) : "" }}</span>
      </template>
      <template v-slot:[`item.amount_paid`]="{ item }">
        <span>
          {{ item.amount_paid ? formatCurrency(item.amount_paid,item.currency) : "" }}
        </span>
      </template>
      <template v-slot:[`item.invalid`]="{ item }">
        <v-icon v-if="item.invalid == 1" color="error">error</v-icon>
        <v-icon v-else-if="item.invalid == 2" color="orange">error</v-icon>
      </template>
      <template v-slot:[`item.first_last_name`]="{ item }">
        <span v-html="item.first_last_name"></span>
      </template>
      <template v-slot:[`item.added`]="{ item }">
        <v-icon v-if="item.added == 1" color="primary">done</v-icon>
      </template>
    </v-data-table>
    <v-alert type="error" class="err" v-model="erroralert">{{
      errortext
    }}</v-alert>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "InteractiveTable",
  props: {
    headers: Array,
    apiLoad: String,
    defaultFilter: String,
    actions: Array,
    queryFilter: Object,
    multiSelect: Boolean,
    statusColorFunc: Function,
    yearFilter: Boolean,
    model: Array,
    dateFilter: Boolean,
    defaultSort: String,
    defaultSortDesc: Boolean,
    pageSize: Number,
    selectFilter: Array,
    selectFilterId: String,
    selectFilterText: String,
    selectFilterLabel: String,
    selectFilterName: String,
    statusVisibleFunc: Function,
    typeFilter: Array,
    typeFilterId: String,
    typeFilterText: String,
    typeFilterLabel: String,
    typeFilterName: String,
    negativeFilter: Boolean,
    participantsFilter: Boolean
  },
  data() {
    return {
      totalRows: 0,
      rows: [],
      loading: true,
      options: {
        sortBy: this.defaultSort ? [this.defaultSort] : [],
        sortDesc: [!!this.defaultSortDesc]
      },
      search: "",
      filter: this.defaultFilter,
      searchCount: 0,
      headerCols: [{ text: "", value: "actions", sortable: false }].concat(
        this.headers
      ),
      selected: [],
      cpage: 1,
      year: (() => {
        var d = new Date();
        return d.getFullYear();
      })(),
      yearList: (() => {
        var d = new Date();
        var yl = [];
        for (var i = d.getFullYear(); i >= 2020; i--) yl.push(i);
        return yl;
      })(),
      itemsPerPage: !!this.model
        ? 1000
        : this.pageSize != null
        ? this.pageSize
        : 100,
      dateVisible: false,
      date: moment().format("YYYY-MM-DD"),
      erroralert: false,
      errortext: "",
      selectedFilter:
        this.selectFilter != null
          ? this.selectFilter[0][this.selectFilterId]
          : null,
      selectedType:
        this.typeFilter != null ? this.typeFilter[0][this.typeFilterId] : null,
      negative: false,
      participants: false
    };
  },
  watch: {
    options: {
      handler() {
        var me = this;
        var elid =
          this.$el.id != null && this.$el.id.length > 0
            ? "#" + this.$el.id + " "
            : "";
        if (!this.model) {
          this.getDataFromApi().then(data => {
            this.rows = data.rows;
            this.totalRows = data.totalRows;
            me.$vuetify.goTo(0, {
              container: elid + ".v-data-table__wrapper",
              duration: 500
            });
          });
        }
      },
      deep: true
    },
    rows: {
      handler() {
        this.$emit("update:model", this.rows);
      },
      deep: true
    },
    model: {
      handler() {
        this.rows = this.model;
        if (this.rows) this.totalRows = this.model.length;
      },
      deep: true
    },
    search: {
      handler() {
        this.searchCount++;
        window.setTimeout(() => {
          if (this.searchCount > 0) {
            this.searchCount = 0;
            this.cpage = 1;
            this.getDataFromApi().then(data => {
              this.rows = data.rows;
              this.totalRows = data.totalRows;
            });
          }
        }, 1000);
      }
    },
    year: {
      handler() {
        this.getDataFromApi().then(data => {
          this.rows = data.rows;
          this.totalRows = data.totalRows;
        });
      }
    },
    selectedFilter: {
      handler() {
        this.getDataFromApi().then(data => {
          this.rows = data.rows;
          this.totalRows = data.totalRows;
        });
      }
    },
    selectedType: {
      handler() {
        this.getDataFromApi().then(data => {
          this.rows = data.rows;
          this.totalRows = data.totalRows;
        });
      }
    },
    date: {
      handler() {
        this.getDataFromApi().then(data => {
          this.rows = data.rows;
          this.totalRows = data.totalRows;
        });
      }
    },
    negative: {
      handler() {
        this.getDataFromApi().then(data => {
          this.rows = data.rows;
          this.totalRows = data.totalRows;
        });
      }
    },
    participants: {
      handler() {
        this.getDataFromApi().then(data => {
          this.rows = data.rows;
          this.totalRows = data.totalRows;
        });
      }
    }
  },
  mounted() {
    this.getDataFromApi().then(data => {
      this.rows = data.rows;
      this.totalRows = data.totalRows;
    });
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      var me = this;
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.$http
          .get(this.apiLoad, {
            params: {
              sort_column: sortBy,
              sort_direction: sortDesc[0] ? "DESC" : "ASC",
              page: page - 1,
              page_size: itemsPerPage,
              filter_column: this.filter,
              filter: this.search,
              qfilter: this.queryFilter,
              year: this.year,
              date: this.date,
              sfilter: this.selectedFilter,
              type: this.selectedType,
              negative: this.negative,
              participants: this.participants
            }
          })
          .then(response => {
            resolve(response.data);
            me.loading = false;
          })
          .catch(error => {
            reject(error);
            me.loading = false;
          });
      });
    },
    callDeleteApi(apiDeleteCall, id) {
      this.loading = true;
      // delete
      this.$http
        .delete(apiDeleteCall, {
          params: {
            id: id
          }
        })
        .then(response => {
          this.loading = false;
          if (response.data.success) {
            this.reload();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    callDownloadApi(apiDownloadCall, id) {
      this.loading = true;
      this.$http
        .get(apiDownloadCall, {
          params: {
            id: id
          },
          responseType: "blob"
        })
        .then(response => {
          this.loading = false;
          if (response.data.size > 0) {
            const fileName = response.headers["x-suggested-filename"];
            const url = window.URL.createObjectURL(new Blob([response.data],{type: 'application/pdf'}));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            this.errortext = "Tražena datoteka ne postoji!";
            this.erroralert = true;
            var me = this;
            setTimeout(() => {
              me.erroralert = false;
            }, 3000);
          }
        })
        .catch(error => {
          this.loading = false;
        });
    },
    callDownloadApiInline(apiDownloadCall, id) {
      this.loading = true;
      this.$http
        .get(apiDownloadCall, {
          params: {
            id: id
          },
          responseType: "blob"
        })
        .then(response => {
          this.loading = false;
          if (response.data.size > 0) {
            const fileName = response.headers["x-suggested-filename"];
            const url = window.URL.createObjectURL(new Blob([response.data],{type: 'application/pdf'}));
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
          } else {
            this.errortext = "Tražena datoteka ne postoji!";
            this.erroralert = true;
            var me = this;
            setTimeout(() => {
              me.erroralert = false;
            }, 3000);
          }
        })
        .catch(error => {
          this.loading = false;
        });
    },
    reload() {
      this.getDataFromApi().then(data => {
        this.rows = data.rows;
        this.totalRows = data.totalRows;
      });
    },
    formatCurrency(amount,currency) {
      if(currency=="HRK" || currency=="HRK+EUR")
        return this.$formatter.format(amount);
      else
        return this.$formatterEUR.format(amount);
    },
    rowClick(value) {
      this.$emit("rowclick", value);
    },
    formatDate(value) {
      return moment(value).format("DD.MM.YYYY.");
    }
  }
};
</script>

<style scoped>
.sw {
  width: 30px;
}
.ssw {
  width: 16px;
}
.no-padding {
  padding-top: 0px !important;
}
.no-wrap {
  white-space: nowrap;
}
.dateinput {
  position: absolute;
  margin-top: 50px;
  margin-left: -40px;
  z-index: 1002;
}
.err {
  position: absolute;
  top: 50px;
}
.negative {
  margin-right: 20px;
  margin-top: 4px;
}
</style>
