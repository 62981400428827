var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"info"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("arrow_back_ios")])],1),_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(_vm._s(this.$route.meta.subtitle))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("autorenew")])],1)],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","align-space-around":"","justify-center":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('InteractiveTable',{ref:"eventRegistrationList",staticClass:"lg-table",attrs:{"headers":[
              { text: 'ID', value: 'id' },
              { text: 'Vrsta', value: 'type' },
              { text: 'Status', value: 'status' },
              { text: 'Iznos', value: 'amount', align: 'end' },
              { text: 'Izvod', value: 'statement_num' },
              { text: 'Datum', value: 'date_created' },
              { text: 'Ime', value: 'contact_first_name' },
              { text: 'Prezime', value: 'contact_last_name' },
              { text: 'Poduzeće', value: 'company_name' },
              { text: 'Org. jedinica', value: 'company_unit' }
            ],"apiLoad":'event/eventregistrations.php?event=' + _vm.$route.params.id,"statusColorFunc":_vm.statusColorFunc,"statusVisibleFunc":_vm.statusVisibleFunc,"actions":[
              {
                id: '1',
                name: 'Prijavnica',
                icon: 'picture_as_pdf',
                apiDownloadCall: 'event/document.php?type=rform',
                color: 'orange'
              },
              {
                id: '2',
                name: 'Ponuda',
                icon: 'picture_as_pdf',
                apiDownloadCall: 'event/document.php?type=quote',
                color: 'error'
              },
              {
                id: '3',
                name: 'Račun za predujam',
                icon: 'picture_as_pdf',
                apiDownloadCall: 'event/document.php?type=preinvoice',
                color: 'secondary'
              },
              {
                id: '4',
                name: 'Račun',
                icon: 'picture_as_pdf',
                apiDownloadCall: 'event/document.php?type=invoice',
                color: 'green'
              }
            ],"defaultFilter":"contact_last_name","defaultSort":"date_created","defaultSortDesc":"","participantsFilter":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }